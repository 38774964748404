<template>
  <div class="statistics-monitor-sugar-container">
    <div class="search-box">
      <Row>
        <Col span="24">
          <h2 class="search-title border-bottom">按条件搜索</h2>
        </Col>
      </Row>
      <Form class="search-form" :label-width="100" ref="formValidate" :model="searchForm" :rules="ruleValidate">
        <Row>
          <Col span="5">
            <Form-item label="会员姓名：">
              <Input v-model="searchForm.member_name" placeholder="请输入..."></Input>
            </Form-item>
          </Col>
          <Col span="1">&nbsp;</Col>
          <Col span="5">
            <Form-item label="性别：">
              <Select clearable v-model="searchForm.sex" placeholder="请选择">
                <Option :value="v.id" v-for="v in sexList" :key="v.id">{{v.name}}</Option>
              </Select>
            </Form-item>
          </Col>
          <Col span="1">&nbsp;</Col>
          <Col span="5">
            <Form-item label="采集方式：">
              <Select clearable v-model="searchForm.type" placeholder="请选择">
                <Option :value="v.id" v-for="v in typeList" :key="v.id">{{v.name}}</Option>
              </Select>
            </Form-item>
          </Col>
        </Row>
        <Row>
          <Col span="8">
            <Form-item label="上传时间：">
              <Date-picker @on-change="changeTime" type="daterange" placeholder="选择日期"></Date-picker>
            </Form-item>
          </Col>
        </Row>
        <Row>
          <Col span="8">
            <Form-item label="空腹血糖：">
              <Row>
                <Col span="10">
                  <Form-item prop="empty_start">
                    <Input v-model="searchForm.empty_start" placeholder="请输入..."></Input>
                  </Form-item>
                </Col>
                <Col span="2" class="tac">至</Col>
                <Col span="10">
                  <Form-item prop="empty_end">
                    <Input v-model="searchForm.empty_end" placeholder="请输入..."></Input>
                  </Form-item>
                </Col>
                <Col span="2" class="tac">mmol/L</Col>
              </Row>
            </Form-item>
          </Col>
          <Col span="1">&nbsp;</Col>
          <Col span="7">
            <Form-item label="饭后半小时：">
              <Row>
                <Col span="10">
                  <Form-item prop="half_start">
                    <Input v-model="searchForm.half_start" placeholder="请输入..."></Input>
                  </Form-item>
                </Col>
                <Col span="2" class="tac">至</Col>
                <Col span="10">
                  <Form-item prop="half_end">
                    <Input v-model="searchForm.half_end" placeholder="请输入..."></Input>
                  </Form-item>
                </Col>
                <Col span="2" class="tac">mmol/L</Col>
              </Row>
            </Form-item>
          </Col>
          <Col span="1">&nbsp;</Col>
          <Col span="7">
            <Form-item label="饭后1小时：">
              <Row>
                <Col span="9">
                  <Form-item prop="one_start">
                    <Input v-model="searchForm.one_start" placeholder="请输入..."></Input>
                  </Form-item>
                </Col>
                <Col span="2" class="tac">至</Col>
                <Col span="9">
                  <Form-item prop="one_end">
                    <Input v-model="searchForm.one_end" placeholder="请输入..."></Input>
                  </Form-item>
                </Col>
                <Col span="4" class="tac">mmol/L</Col>
              </Row>
            </Form-item>
          </Col>
        </Row>
        <Row>
          <Col span="8">
            <Form-item label="饭后2小时：">
              <Row>
                <Col span="10">
                  <Form-item prop="two_start">
                    <Input v-model="searchForm.two_start" placeholder="请输入..."></Input>
                  </Form-item>
                </Col>
                <Col span="2" class="tac">至</Col>
                <Col span="10">
                  <Form-item prop="two_end">
                    <Input v-model="searchForm.two_end" placeholder="请输入..."></Input>
                  </Form-item>
                </Col>
                <Col span="2" class="tac">mmol/L</Col>
              </Row>
            </Form-item>
          </Col>
          <Col span="1">&nbsp;</Col>
          <Col span="7">
            <Form-item label="饭后3小时：">
              <Row>
                <Col span="10">
                  <Form-item prop="three_start">
                    <Input v-model="searchForm.three_start" placeholder="请输入..."></Input>
                  </Form-item>
                </Col>
                <Col span="2" class="tac">至</Col>
                <Col span="10">
                  <Form-item prop="three_end">
                    <Input v-model="searchForm.three_end" placeholder="请输入..."></Input>
                  </Form-item>
                </Col>
                <Col span="2" class="tac">mmol/L</Col>
              </Row>
            </Form-item>
          </Col>
          <Col span="1">&nbsp;</Col>
          <Col span="7">
            <Form-item label="年龄：">
              <Row>
                <Col span="10">
                  <Form-item prop="age_start">
                    <Input v-model="searchForm.age_start" placeholder="请输入..."></Input>
                  </Form-item>
                </Col>
                <Col span="2" class="tac">至</Col>
                <Col span="10">
                  <Form-item prop="age_end">
                    <Input v-model="searchForm.age_end" placeholder="请输入..."></Input>
                  </Form-item>
                </Col>
                <Col span="2" class="tac">岁</Col>
              </Row>
            </Form-item>
          </Col>
        </Row>
        <Row>
          <Col span="7">
            <Form-item label="省份：">
              <Input disabled v-if="this.power.indexOf('数据统计--查询全国数据的权限') === -1 && this.power.indexOf('数据统计--查询区域数据的权限') === -1" v-model="pro_name"></Input>
              <Select clearable v-model="searchForm.pid" placeholder="请选择" @on-change="changePro" v-else>
                <Option :value="v.id" v-for="v in proList" :key="v.id">{{v.name}}</Option>
              </Select>
            </Form-item>
          </Col>
          <Col span="1">&nbsp;</Col>
          <Col span="7">
            <Form-item label="城市：">
              <Input disabled v-if="this.power.indexOf('数据统计--查询全国数据的权限') === -1 && this.power.indexOf('数据统计--查询区域数据的权限') === -1" v-model="city_name"></Input>
              <Select clearable v-model="searchForm.cid" placeholder="请选择" @on-change="changeCity" v-else>
                <Option :value="v.id" v-for="v in cityList" :key="v.id">{{v.name}}</Option>
              </Select>
            </Form-item>
          </Col>
          <Col span="1">&nbsp;</Col>
          <Col span="7">
            <Form-item label="门店：">
              <Input disabled v-if="this.power.indexOf('数据统计--查询全国数据的权限') === -1 && this.power.indexOf('数据统计--查询区域数据的权限') === -1" v-model="branch_name"></Input>
              <Select clearable v-model="searchForm.sid" placeholder="请选择" v-else>
                <Option :value="v.id" v-for="v in storeList" :key="v.id">{{v.name}}</Option>
              </Select>
            </Form-item>
          </Col>
        </Row>
        <div class="tac">
          <Button type="primary" class="search-btn" @click="handleSubmit('formValidate')">搜索</Button>
        </div>
      </Form>
    </div>
    <div class="export-div clearfix">
      <a href="javascript:;" class="export-btn" @click="exportData()" v-if="this.power.indexOf('数据统计--数据导出权限') > -1">导出数据</a>
    </div>
    <Table stripe :columns="columns" :data="sugarData" @on-selection-change="selectOne" ref="table"></Table>
    <Row>
      <Col span="12">
        <div class="table-oprate">
          <!--<Button @click="">删除</Button>-->
        </div>
      </Col>
      <Col span="12">
        <span class="records">共{{pageTotal}}条记录</span>
      </Col>
    </Row>
    <div class="page-nations-wrapper">
      <div class="page-nations">
        <Page :total="pageTotal" :current="searchForm.page" :page-size="searchForm.size" @on-change="changePage"></Page>
      </div>
    </div>
  </div>
</template>

<script type="text/ecmascript-6">
import warningService from '@/services/warningService';
import statisticsService from '@/services/statisticsService';
export default {
	data() {
		const emptySmall = (rule, value, callback) => {
			if (value === '') {
				if (this.searchForm.empty_end !== '') {
					this.$refs.formValidate.validateField('empty_end');
				}
				callback();
			} else {
				if (Number.isNaN(Number(value))) {
					callback(new Error('请输入数字值'));
				} else {
					if (this.searchForm.empty_end !== '') {
						this.$refs.formValidate.validateField('empty_end');
					}
					callback();
				}
			}
		};
		const emptyLarge = (rule, value, callback) => {
			if (value === '') {
				callback();
			} else {
				if (Number.isNaN(Number(value))) {
					callback(new Error('请输入数字值'));
				} else {
					if (
						this.searchForm.empty_start !== '' &&
						Number(value) < Number(this.searchForm.empty_start)
					) {
						callback(new Error('请输入更大数值'));
					}
					callback();
				}
			}
		};
		const halfSmall = (rule, value, callback) => {
			if (value === '') {
				if (this.searchForm.half_end !== '') {
					this.$refs.formValidate.validateField('half_end');
				}
				callback();
			} else {
				if (Number.isNaN(Number(value))) {
					callback(new Error('请输入数字值'));
				} else {
					if (this.searchForm.half_end !== '') {
						this.$refs.formValidate.validateField('half_end');
					}
					callback();
				}
			}
		};
		const halfLarge = (rule, value, callback) => {
			if (value === '') {
				callback();
			} else {
				if (Number.isNaN(Number(value))) {
					callback(new Error('请输入数字值'));
				} else {
					if (
						this.searchForm.half_start !== '' &&
						Number(value) < Number(this.searchForm.half_start)
					) {
						callback(new Error('请输入更大数值'));
					}
					callback();
				}
			}
		};
		const oneSmall = (rule, value, callback) => {
			if (value === '') {
				if (this.searchForm.one_end !== '') {
					this.$refs.formValidate.validateField('one_end');
				}
				callback();
			} else {
				if (Number.isNaN(Number(value))) {
					callback(new Error('请输入数字值'));
				} else {
					if (this.searchForm.one_end !== '') {
						this.$refs.formValidate.validateField('one_end');
					}
					callback();
				}
			}
		};
		const oneLarge = (rule, value, callback) => {
			if (value === '') {
				callback();
			} else {
				if (Number.isNaN(Number(value))) {
					callback(new Error('请输入数字值'));
				} else {
					if (
						this.searchForm.one_start !== '' &&
						Number(value) < Number(this.searchForm.one_start)
					) {
						callback(new Error('请输入更大数值'));
					}
					callback();
				}
			}
		};
		const twoSmall = (rule, value, callback) => {
			if (value === '') {
				if (this.searchForm.two_end !== '') {
					this.$refs.formValidate.validateField('two_end');
				}
				callback();
			} else {
				if (Number.isNaN(Number(value))) {
					callback(new Error('请输入数字值'));
				} else {
					if (this.searchForm.two_end !== '') {
						this.$refs.formValidate.validateField('two_end');
					}
					callback();
				}
			}
		};
		const twoLarge = (rule, value, callback) => {
			if (value === '') {
				callback();
			} else {
				if (Number.isNaN(Number(value))) {
					callback(new Error('请输入数字值'));
				} else {
					if (
						this.searchForm.two_start !== '' &&
						Number(value) < Number(this.searchForm.two_start)
					) {
						callback(new Error('请输入更大数值'));
					}
					callback();
				}
			}
		};
		const threeSmall = (rule, value, callback) => {
			if (value === '') {
				if (this.searchForm.three_end !== '') {
					this.$refs.formValidate.validateField('three_end');
				}
				callback();
			} else {
				if (Number.isNaN(Number(value))) {
					callback(new Error('请输入数字值'));
				} else {
					if (this.searchForm.three_end !== '') {
						this.$refs.formValidate.validateField('three_end');
					}
					callback();
				}
			}
		};
		const threeLarge = (rule, value, callback) => {
			if (value === '') {
				callback();
			} else {
				if (Number.isNaN(Number(value))) {
					callback(new Error('请输入数字值'));
				} else {
					if (
						this.searchForm.three_start !== '' &&
						Number(value) < Number(this.searchForm.three_start)
					) {
						callback(new Error('请输入更大数值'));
					}
					callback();
				}
			}
		};
		const ageSmall = (rule, value, callback) => {
			if (value === '') {
				if (this.searchForm.age_end !== '') {
					this.$refs.formValidate.validateField('age_end');
				}
				callback();
			} else {
				if (Number.isNaN(Number(value))) {
					callback(new Error('请输入数字值'));
				} else {
					if (this.searchForm.age_end !== '') {
						this.$refs.formValidate.validateField('age_end');
					}
					callback();
				}
			}
		};
		const ageLarge = (rule, value, callback) => {
			if (value === '') {
				callback();
			} else {
				if (Number.isNaN(Number(value))) {
					callback(new Error('请输入数字值'));
				} else {
					if (
						this.searchForm.age_start !== '' &&
						Number(value) < Number(this.searchForm.age_start)
					) {
						callback(new Error('请输入更大数值'));
					}
					callback();
				}
			}
		};
		return {
			searchForm: {
				page: 1,
				size: 10,
				member_name: '',
				sex: '',
				pid: '',
				cid: '',
				sid: '',
				type: '',
				start: '',
				end: '',
				empty_start: '',
				empty_end: '',
				half_start: '',
				half_end: '',
				one_start: '',
				one_end: '',
				two_start: '',
				two_end: '',
				three_start: '',
				three_end: '',
				age_start: '',
				age_end: '',
			},
			exportForm: {
				member_name: '',
				sex: '',
				pid: '',
				cid: '',
				sid: '',
				type: '',
				start: '',
				end: '',
				empty_start: '',
				empty_end: '',
				half_start: '',
				half_end: '',
				one_start: '',
				one_end: '',
				two_start: '',
				two_end: '',
				three_start: '',
				three_end: '',
				age_start: '',
				age_end: '',
			},
			sexList: [
				{
					id: 0,
					name: '男',
				},
				{
					id: 1,
					name: '女',
				},
			],
			typeList: [
				{
					id: 0,
					name: '手动',
				},
				{
					id: 1,
					name: 'app',
				},
			],
			proList: [],
			cityList: [],
			city: {},
			storeList: [],
			store: {},
			columns: [
				// { type: 'selection', width: 60, align: 'center' },
				{ title: '所属分院', key: 'branch_name', align: 'center' },
				{
					title: '会员姓名',
					key: 'member_name',
					align: 'center',
					render: (h, params) => {
						return h('div', [
							h(
								'a',
								{
									style: {
										color: '#ff8400',
									},
									attrs: {
										target: '_blank',
										href:
											'#/base/member/detail/' +
											this.sugarData[params.index].member_id +
											'/' +
											2 +
											'/' +
											1,
									},
								},
								params.row.member_name,
							),
						]);
					},
				},
				{
					title: '性别',
					key: 'sex',
					align: 'center',
					render: (h, params) => {
						if (params.row.sex === 0) {
							return h('div', '男');
						} else if (params.row.sex === 1) {
							return h('div', '女');
						} else {
							return h('div', params.row.sex);
						}
					},
				},
				{
					title: '年龄',
					key: 'age',
					align: 'center',
					render: (h, params) => {
						if (params.row.age === null) {
							return h('div', params.row.age);
						} else {
							return h('div', params.row.age + '岁');
						}
					},
				},
				{
					title: '采集方式',
					key: 'type',
					align: 'center',
					render: (h, params) => {
						if (params.row.type === '0') {
							return h('div', '手动');
						} else if (params.row.type === '1') {
							return h('div', 'app');
						} else {
							return h('div', params.row.type);
						}
					},
				},
				{
					title: '空腹血糖',
					key: 'empty',
					align: 'center',
					render: (h, params) => {
						if (params.row.empty === null || params.row.empty === 0) {
							return h('div', '');
						} else {
							return h('div', params.row.empty + 'mmol/L');
						}
					},
				},
				{
					title: '饭后半小时',
					key: 'half_hour',
					align: 'center',
					render: (h, params) => {
						if (params.row.half_hour === null || params.row.half_hour === 0) {
							return h('div', '');
						} else {
							return h('div', params.row.half_hour + 'mmol/L');
						}
					},
				},
				{
					title: '饭后1小时',
					key: 'one_hour',
					align: 'center',
					render: (h, params) => {
						if (params.row.one_hour === null || params.row.one_hour === 0) {
							return h('div', '');
						} else {
							return h('div', params.row.one_hour + 'mmol/L');
						}
					},
				},
				{
					title: '饭后2小时',
					key: 'two_hour',
					align: 'center',
					render: (h, params) => {
						if (params.row.two_hour === null || params.row.two_hour === 0) {
							return h('div', '');
						} else {
							return h('div', params.row.two_hour + 'mmol/L');
						}
					},
				},
				{
					title: '饭后3小时',
					key: 'three_hour',
					align: 'center',
					render: (h, params) => {
						if (params.row.three_hour === null || params.row.three_hour === 0) {
							return h('div', '');
						} else {
							return h('div', params.row.three_hour + 'mmol/L');
						}
					},
				},
				{ title: '上传时间', key: 'create_time', align: 'center' },
			],
			sugarData: [],
			pageTotal: 0,
			ruleValidate: {
				empty_start: [{ validator: emptySmall, trigger: 'blur' }],
				empty_end: [{ validator: emptyLarge, trigger: 'blur' }],
				half_start: [{ validator: halfSmall, trigger: 'blur' }],
				half_end: [{ validator: halfLarge, trigger: 'blur' }],
				one_start: [{ validator: oneSmall, trigger: 'blur' }],
				one_end: [{ validator: oneLarge, trigger: 'blur' }],
				two_start: [{ validator: twoSmall, trigger: 'blur' }],
				two_end: [{ validator: twoLarge, trigger: 'blur' }],
				three_start: [{ validator: threeSmall, trigger: 'blur' }],
				three_end: [{ validator: threeLarge, trigger: 'blur' }],
				age_start: [{ validator: ageSmall, trigger: 'blur' }],
				age_end: [{ validator: ageLarge, trigger: 'blur' }],
			},
			power: '',
			pro_name: '',
			city_name: '',
			branch_name: '',
		};
	},
	created() {
		this.power = localStorage.getItem('userPower');
		this.pro_name = JSON.parse(localStorage.getItem('userInfo')).user_info.pro_name;
		this.city_name = JSON.parse(localStorage.getItem('userInfo')).user_info.city_name;
		this.branch_name = JSON.parse(localStorage.getItem('userInfo')).user_info.branch_name;
		if (
			this.power.indexOf('数据统计--查询全国数据的权限') === -1 &&
			this.power.indexOf('数据统计--查询区域数据的权限') === -1
		) {
			this.searchForm.pid = JSON.parse(localStorage.getItem('userInfo')).user_info.pid;
			this.searchForm.cid = JSON.parse(localStorage.getItem('userInfo')).user_info.cid;
			this.searchForm.sid = JSON.parse(localStorage.getItem('userInfo')).user_info.sid;
		}
		this.getSugarData();
		warningService.getWarningSelect().then((data) => {
			this.proList = data.branch.pro;
			this.city = data.branch.city;
			this.store = data.branch.store;
		});
	},
	methods: {
		changePro() {
			this.cityList = this.city[this.searchForm.pid];
			this.searchForm.cid = '';
		},
		changeCity() {
			this.storeList = this.store[this.searchForm.cid];
			this.searchForm.sid = '';
		},
		getSugarData() {
			statisticsService.getSugarData(this.searchForm).then((data) => {
				this.sugarData = data.list;
				this.pageTotal = data.row_size;
			});
		},
		handleSubmit(name) {
			this.$refs[name].validate((valid) => {
				if (valid) {
					this.searchForm.page = 1;
					this.getSugarData();
					this.exportForm.member_name = this.searchForm.member_name;
					this.exportForm.sex = this.searchForm.sex;
					this.exportForm.pid = this.searchForm.pid;
					this.exportForm.cid = this.searchForm.cid;
					this.exportForm.sid = this.searchForm.sid;
					this.exportForm.start = this.searchForm.start;
					this.exportForm.end = this.searchForm.end || '';
					this.exportForm.type = this.searchForm.type;
					this.exportForm.empty_start = this.searchForm.empty_start;
					this.exportForm.empty_end = this.searchForm.empty_end;
					this.exportForm.half_start = this.searchForm.half_start;
					this.exportForm.half_end = this.searchForm.half_end;
					this.exportForm.one_start = this.searchForm.one_start;
					this.exportForm.one_end = this.searchForm.one_end;
					this.exportForm.two_start = this.searchForm.two_start;
					this.exportForm.two_end = this.searchForm.two_end;
					this.exportForm.three_start = this.searchForm.three_start;
					this.exportForm.three_end = this.searchForm.three_end;
					this.exportForm.age_start = this.searchForm.age_start;
					this.exportForm.age_end = this.searchForm.age_end;
				}
			});
		},
		changeTime(date) {
			this.searchForm.start = date[0];
			this.searchForm.end = date[1];
		},
		changePage(page) {
			this.searchForm.page = page;
			this.getSugarData();
		},
		selectOne() {},
		exportData() {
			location.href =
				'http://conlife.sskh.net:9928/api/sugar/export?member_name=' +
				this.exportForm.member_name +
				'&sex=' +
				this.exportForm.sex +
				'&pid=' +
				this.exportForm.pid +
				'&cid=' +
				this.exportForm.cid +
				'&sid=' +
				this.exportForm.sid +
				'&start=' +
				this.exportForm.start +
				'&end=' +
				this.exportForm.end +
				'&type=' +
				this.exportForm.type +
				'&empty_start=' +
				this.exportForm.empty_start +
				'&empty_end=' +
				this.exportForm.empty_end +
				'&half_start=' +
				this.exportForm.half_start +
				'&half_end=' +
				this.exportForm.half_end +
				'&one_start=' +
				this.exportForm.one_start +
				'&one_end=' +
				this.exportForm.one_end +
				'&two_start=' +
				this.exportForm.two_start +
				'&two_end=' +
				this.exportForm.two_end +
				'&three_start=' +
				this.exportForm.three_start +
				'&three_end=' +
				this.exportForm.three_end +
				'&age_start=' +
				this.exportForm.age_start +
				'&age_end=' +
				this.exportForm.age_end;
		},
	},
};
</script>

<style lang="css" scoped>
.statistics-monitor-sugar-container .search-box {
  border: 1px solid #d7dde4;
}
</style>
